<template>
  <div id="gsde_brw_app_div">
    <BRW
      :initial_bl="bl ? Number(bl) : undefined"
      :utm_base="utm_base ? String(utm_base) : undefined"
      :ad_anzahl="ad_anzahl ? Number(ad_anzahl) : undefined"
      :pixel_data="pixel_data ? String(pixel_data) : undefined"
      :ad_k="ad_k ? String(ad_k) : undefined"
      :ad_s="ad_s ? String(ad_s) : undefined"
      :ad_m="ad_m ? String(ad_m) : undefined"
      :anzeige_text="anzeige_text ? String(anzeige_text) : undefined"
    />
  </div>
</template>

<script>
import BRW from './BRW.vue'

export default {
  name: 'AppEmbed',
  components: {
    BRW
  },
  props: {
    bl: null,
    utm_base: null,
    ad_anzahl: null,
    pixel_data: null,
    ad_k: null,
    ad_s: null,
    ad_m: null,
    anzeige_text: null,
  }
}
</script>

<style scoped lang="scss">
</style>
