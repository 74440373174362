<template>
<div ref="widget_main" id="main_div" class="main_div" v-bind:class="{ 'main_div_im': integration_mode, 'modal-open': modal_info_is_showing || full_modal_active }">

  <div v-if="modal_info_is_showing || full_modal_active" class="modal-backdrop fade in"></div>
  <div
      class="modal fade"
      :class="{'in': modal_info_is_showing}"
      :style="{'display': modal_info_is_showing ? 'block' : 'none'}"
      id="modal_info"
      tabindex="-1"
      role="dialog" aria-labelledby="modal_info_title"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click.prevent="modal_info_is_showing=false" aria-label="Schließen"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="modal_info_title">{{ modal_info_title }}</h4>
        </div>
        <div class="modal-body">
          <p id="modal_info_text" style="text-align: left;" v-html="modal_info_text"></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click.prevent="modal_info_is_showing=false">OK</button>
        </div>
      </div>
    </div>
  </div>

  <CoolLightBox 
    :items="formular_items_box" 
    :index="formular_index"
    :slideshow="false"
    thumbsPosition="bottom"
    @close="formular_index = null"
    :translations="{
      previous: 'Zurück',
      next: 'Weiter',
      showThumbNails: 'Vorschau anzeigen',
      fullScreen: 'Vollbild',
      close: 'Schließen' 
    }"
  >
  </CoolLightBox>

  <div
      :class="{'modal in': full_modal_active}"
      style="display: block;"
      id="full_modal"
      tabindex="-1"
  >
  <div :class="{'modal-dialog modal-lg': full_modal_active}" role="document">
    <div :class="{'modal-content': full_modal_active}">
      <div v-if="full_modal_active" class="modal-header top_header_title_line_modal">
        <button type="button" class="close" @click.prevent="on_close_full_modal" aria-label="Schließen"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="modal_info_title">Bodenrichtwert</h4>
      </div>
      <div>

  <ul class="pricing-table">
    <li class="full_box" v-bind:class="{ 'full_box_im': integration_mode }">

      <div v-if="!full_modal_active">
        <div class="top_header_title_line">
          <h1>Bodenrichtwert</h1>
        </div>
      </div>

      <div class="pricing-inner">
        <div class="pricing-content">

          <div v-if="switch_to_full_modal && full_modal_button && !full_modal_active">
            <div class="question_button_box">
              <button @click="full_modal_active=true" class="button">Jetzt starten</button>
            </div>
          </div>

          <div v-else-if="curq===null">
            <p class="question_text">
            </p>
            <div class="question_button_box">
              <button @click="start" class="button">Starten</button>
            </div>
          </div>

          <div v-else-if="curq===qs['bl']">
            <div class="row" style="margin: 0;">
              <h3 class="mini_header_small">
                Für welches Bundes&shy;land möchten Sie den Boden&shy;richt&shy;wert abrufen?
              </h3>
              <div class="col-lg-3">
                <BLMap @on_bl="on_bl" :mini_mode="true"></BLMap>
              </div>
              <div class="col-lg-9">
                <h3 class="mini_header_large" style="text-align: left; margin-left: 15px;">
                  Für welches Bundes&shy;land möchten Sie den Boden&shy;richt&shy;wert abrufen?
                </h3>
                <div class="row mini_row">
                  <div class="col-lg-7">
                    <select
                      type="text"
                      id="bl_select"
                      name="bl_select"
                      v-model="bl"
                      class="form-control mini_mode_select"
                    >
                      <option value="null">— Bundesland wählen —</option>
                      <option
                        v-for="option in bl_names"
                        v-bind:value="option.value"
                        v-bind:key="option.value"
                      >{{ option.text }}</option>
                    </select>
                  </div>
                  <div class="col-lg-5">
                    <button @click='on_bl()' class="button weiter_button weiter_button_mini">Weiter</button>
                  </div>
                </div>
                <p v-if="bl_error" class="question_box_error" style="margin-top: 16px;">
                  Bitte zuerst ein Bundesland auswählen.
                </p>
              </div>
            </div>
          </div>

          <div v-else-if="curq===qs['ergebnis']">
            <div class="row" style="margin: 0;">
              <h3 class="mini_header_small">
                <div style="float: right; margin-top: -16px;">
                  <button @click.prevent="nochmal" class="btn btn-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </div>
                Das ist beim Abruf der Boden&shy;richt&shy;werte zu beachten
              </h3>
              <div class="col-lg-3">
                <BLMaphigHlightable :highlight="bl"></BLMaphigHlightable>
              </div>
              <div class="col-lg-9">
                <h3 class="mini_header_large" style="text-align: left; margin-left: 15px;">
                  <div style="float: right; margin-top: -16px;">
                    <button @click.prevent="nochmal" class="btn btn-link">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
                  Das ist beim Abruf der Boden&shy;richt&shy;werte zu beachten
                </h3>
                <div class="row mini_row">
                  <div class="col-lg-8">
                    <div class="alert alert-success" role="alert" style="text-align: justify;">
                      <span v-html="alert_text"></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <a :href="brw_link_for_bl" target="_blank" class="button weiter_button weiter_button_mini">Abruf starten</a>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="formular_items_box.length > 0"
              class="row" style="margin: 16px 30px 0 30px;"
            >
              <p style="text-align: left;">
                Anleitung zum Abruf der Bodenrichtwerte:
              </p>
              <div class="images-wrapper" style="width: 100%; display: flex; flex: 1 0 auto; overflow: hidden;">
                <div
                  class="image"
                  v-for="(image, imageIndex) in formular_items_show"
                  :key="imageIndex"
                  @click="formular_index = imageIndex"
                  :style="{
                    minWidth: '106px',
                    width: '106px',
                    height: '160px',
                    cursor: 'zoom-in',
                    marginLeft: '0',
                    marginRight: '8px',
                    backgroundImage: 'url(' + image.thumb + ')'
                  }"
                >
                </div>
              </div>
            </div>
            
            <div class="row" style="margin: 16px 30px 0 30px;">
              <AdBoxes
                widget_name="W6"
                :anzeige_text="anzeige_text_current"
                :utm_base="utm_base"
                :ad_anzahl="ad_anzahl"
                :ad_k="ad_k"
                :ad_s="ad_s"
                :ad_m="ad_m"
              >
              </AdBoxes>
            </div>
          </div>

          <div v-else>
            <p class="question_text">
              Es ist ein unbekannter Fehler aufgetreten, bitte versuchen Sie es nochmal.
            </p>
            <div class="question_button_box">
              <button @click="nochmal" class="button">Nochmal</button>
            </div>
          </div>

        </div>
      </div>
    </li>
  </ul>

      </div>
    </div>
  </div>
  </div>

	<img v-if="pixel_data && pixel_utm" :src='"https://widget.grundsteuer.de/pixel.php?" + pixel_data + "&utm_source=" + pixel_utm' />
  <br style="clear:left;" />
</div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import BLMap from '../grste3/BLMap.vue';
import BLMaphigHlightable from '../kosten/BLMapHighlightable.vue';
import AdBoxes from '../shared/AdBoxes.vue';

export default {
  name: "BRW",
  components: {
    CoolLightBox,
    AdBoxes,
    BLMap,
    BLMaphigHlightable
},
  props: {
    integration_mode: Boolean,
    initial_bl: null,
    switch_to_full_modal: Boolean,
    full_modal_button: Boolean,

    utm_base: String,
    pixel_data: String,
    ad_anzahl: Number,
    ad_k: String,
    ad_s: String,
    ad_m: String,
    anzeige_text: String,
  },
  mounted: function() {
    const domain = window.location.hostname;
    if (this.utm_base) {
      this.pixel_utm = this.utm_base + "W6";
    }
    else if (domain == "grundsteuer.de") {
      this.pixel_utm = "GS_U_C_W6";
    }
    else if (domain == "hebesatz.grundsteuer.de") {
      this.pixel_utm = "HS_U_C_W6";
    }
    else {
      this.pixel_utm = "GS_U_C_W6";
    }

    let params = new URLSearchParams(window.location.search);
    if (params.has("bl")) {
      this.bl_start = parseInt(params.get("bl"));
      if (isNaN(this.bl_start)) {
        this.bl_start = null;
      }
    }
    else if (this.initial_bl) {
      this.bl_start = this.initial_bl;
    }

    const bls = this.bl_names.map(b => b["value"]);
    if (this.bl_start && bls.includes(this.bl_start)) {
      this.bl = this.bl_start;
      this.start_bl();
    }
  },
  data: function() {
    return {
      pixel_utm: null,

      curq: 1,
      qstack: [ 1 ],
      qs: {
        "bl": 1,
        "ergebnis": 2,
      },

      bl_start: null,
      bl: null,
      bl_error: null,
      bl_names: [
        { value: 8, text: "Baden-Württemberg" },
        { value: 9, text: "Bayern" },
        { value: 11, text: "Berlin" },
        { value: 12, text: "Brandenburg" },
        { value: 4, text: "Bremen" },
        { value: 2, text: "Hamburg" },
        { value: 6, text: "Hessen" },
        { value: 13, text: "Mecklenburg-Vorpommern" },
        { value: 3, text: "Niedersachsen" },
        { value: 5, text: "Nordrhein-Westfalen" },
        { value: 7, text: "Rheinland-Pfalz" },
        { value: 10, text: "Saarland" },
        { value: 14, text: "Sachsen" },
        { value: 15, text: "Sachsen-Anhalt" },
        { value: 1, text: "Schleswig-Holstein" },
        { value: 16, text: "Thüringen" }
      ],

      BRW_URLS: {
        8: "https://www.gutachterausschuesse-bw.de/borisbw/?lang=de",
        11: "https://fbinter.stadt-berlin.de/boris/",
        12: "https://www.boris-brandenburg.de/boris-bb/",
        4: "https://immobilienmarkt.niedersachsen.de/bodenrichtwerte",
        6: "https://www.geoportal.hessen.de/map?WMC=39",
        13: "https://www.geodaten-mv.de/grundsteuerdaten/",
        3: "https://grundsteuer-viewer.niedersachsen.de/b",
        5: "https://grundsteuer-geodaten.nrw.de/",
        7: "http://www.boris.rlp.de/",
        10: "https://geoportal.saarland.de/mapbender/frames/index.php?lang=de&gui_id=Steuer-BORIS&WMC=5416",
        14: "https://geoviewer.sachsen.de/mapviewer/resources/apps/boris/index.html",
        15: "http://www.grundsteuerdaten.sachsen-anhalt.de/",
        1: "https://danord.gdi-sh.de/viewer/resources/apps/bodenrichtwertefuergrundsteuerzweckesh/index.html?lang=de#/",
        16: "https://thueringenviewer.thueringen.de/thviewer/grundsteuer.html",
        9: "https://geoportal.bayern.de/bayernatlas/bodenrichtwerte",
        2: "https://www.geoportal-hamburg.de/boris/"
      },

      modal_info_title: '',
      modal_info_text: '',
      modal_info_is_showing: false,

      // Widget is in "full modal" mode
      full_modal_active: false,
      // Anleitungen
      formular_index: null,
      formular_items: {
        0: [],
        /*6: [
          {
            src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Screenshot_1.png',
            thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Screenshot_1_thumb.png',
          },
          {
            src: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Screenshot_2.png',
            thumb: 'https://widget.grundsteuer.de/formulare/Screenshots_Hessen/Screenshot_2_thumb.png',
          },
        ],*/
      },
    };
  },
  computed: {
    formular_items_show: function() {
      return this.formular_items_box.slice(0, 7);
    },

    formular_items_box: function() {
      if (this.bl in this.formular_items) {
        return this.formular_items[this.bl];
      }
      return this.formular_items[0];
    },

    brw_link_for_bl: function() {
      if (this.bl in this.BRW_URLS) {
        return this.BRW_URLS[this.bl];
      }
      return "https://www.bodenrichtwerte-boris.de/borisde/?lang=de";
    },

    bl_name: function() {
      for (const blo of this.bl_names) {
        if (blo["value"] === this.bl) {
          return blo["text"];
        }
      }
      return "";
    },

    anzeige_text_current: function() {
      if (this.anzeige_text) {
        return this.anzeige_text;
      }
      
      if (this.bl === 9 || this.bl === 2) {
        return "Jetzt die Grund&shy;steuer&shy;erklärung rechtzeitig erledigen"
      }
      return "Jetzt Grund&shy;steuer&shy;erklärung erledigen und keine Fehler beim Boden&shy;richt&shy;wert machen"
    },

    alert_text: function() {
      if (this.bl === 9 || this.bl === 2) {
        return "Im Landesmodell von " + this.bl_name + " wird der Bodenrichtwert zur Berechnung der neuen Grundsteuer nicht benötigt."
      }
      return "Die Bodenrichtwerte sind von der Lage des Grundstücks abhängig, weshalb beim Abruf die genaue Adresse anzugeben ist. Für die Grundsteuer ist jeweils der Bodenrichtwert zum 1. Januar 2022 maßgebend."
    },
  },
  watch: {
    curq: function(new_val, old_val) {
      if (new_val > old_val) {
        this.qstack.push(new_val);
      }
    }
  },
  methods: {
    track_event: function(step) {
      const event_label = "W6_" + step;

      if (window.ga) {
        window.ga('send', 'event', 'Widget', 'Klick', event_label);
      }
      if (window._paq) {
        window._paq.push(['trackEvent', 'Widget', 'Klick', event_label]);
      }
    },

    on_bl: function(data) {
      if (data && "bl" in data) {
        this.bl = data.bl;
      }

      if (this.bl) {
        this.bl_error = false;
        
        if (this.bl === 9 || this.bl === 2) {
          this.track_event("1_1")
        }
        else {
          this.track_event("1_2")
        }

        this.curq = this.qs['ergebnis'];

        if (this.switch_to_full_modal) {
          this.full_modal_active = true;
        }
      }
      else {
        this.bl_error = true;
      }
    },

    on_toggle_info_text: function(title, text) {
      this.modal_info_title = title;
      this.modal_info_text = text;
      this.modal_info_is_showing = true;
    },

    on_close_full_modal: function() {
      // Close modal and restart widget
      this.start();
      this.full_modal_active = false;
    },

    start: function() {
      const start_id = this.qs['bl'];
      this.qstack = [ start_id ];
      this.curq = start_id;
    },

    start_bl: function() {
      const start_id = this.qs['ergebnis'];
      this.qstack = [ start_id ];
      this.curq = start_id;
    },

    nochmal: function() {
      this.bl = null;
      this.bl_error = null;

      this.start();
    },
    
    f_zurueck: function() {
      if (this.qstack.length > 1) {
        this.qstack.pop();
        const last_q = this.qstack[this.qstack.length-1];
        this.curq = last_q;
      }
    },
  }
};
</script>

<style scoped lang="scss">
@import '~vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

#gsde_bl_area_map a:hover path {
  fill: #f0ad4e !important;
}

.main_div {
  margin: 0 auto;
  text-align: center;
}

.main_div_im {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  text-align: center !important;
}

.full_box {
  width: 100%;
}

.full_box_im {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.question_text {
  font-size: 150%;
  font-weight: bold;
}

.pricing-table {
  margin: 0 !important;
}

.pricing-table > li {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pricing-inner::before {
  top: 0px !important;
  bottom: 0px !important;
  border: 0 !important;
}

.pricing-inner:hover::before {
  border: 0 !important;
  border-color: transparent !important;
}

.pricing-inner {
  //padding: 60px 40px 90px 40px;
  padding: 16px 40px 90px 40px !important;
}

@media only screen and (max-width: 767px) {
  .pricing-inner {
    padding: 16px 2px 90px 2px !important;
  }
}

.pricing-title h3 {
  text-transform: none !important;
  font-weight: normal !important;
}

.pricing-content {
  padding-top: 20px !important;
}

.pricing-content .button p {
  line-height: 1.5em;
  margin-top: 16px !important;
}

.question_box {
  margin: 16px 0 !important;
}

.question_box select {
  height: 40px !important;
  padding: 5px 15px !important;
}

.question_box .small-select {
  display: inline-block;
  width: inherit;
  width: 35%;
}

.question_box_error {
  color: #a94442 !important;
}

.question_button_box {
  margin-top: 24px !important;
}

.question_back_button_box button {
  margin-top: 8px !important;
}

.question_back_button_box button {
  background-color: transparent !important;
  border: 0 !important;
}

.question_back_button_box button:hover {
  background-color: transparent !important;
  border: 2px solid #1f2a30 !important;
  color: #1f2a30 !important;
  transition-duration: 100ms !important;
}

.question_box_buttons a p {
  text-transform: none;
}

.question_box_buttons a {
  transition-duration: 100ms !important;
  color: #000;
}

.question_box_buttons a:hover {
  color: #fff !important;
}

.question_box_buttons > button {
  text-transform: none;
}

/*.question_box_buttons > button:hover {
  border: solid rgb(66, 195, 122) 4px !important;
  background-color: #dedede !important;
  color: #000 !important;
}*/

.question_box_buttons {
  margin: 16px 0 !important;
}

.question_box_buttons button p {
  margin-top: -4px !important;
}

.question_box_buttons_small button p {
  margin-top: -24px !important;
}

.question_box_buttons button {
  height: 150px !important;
  width: 150px !important;
  margin: 6px 6px !important;
}

.question_box_buttons button svg {
  vertical-align: middle !important;
}

.question_box_buttons_small button {
  height: 150px !important;
  width: 180px !important;
  margin: 16px 16px !important;
}

.question_box_buttons_images {
  margin: 16px 0 !important;
  border-top: 0;
}

.question_box_buttons_images button {
  width: 250px !important;
  height: 100% !important;
  margin: 16px 16px !important;
  background-color: #fff !important;
  border-color: transparent !important;
  border-radius: 24px;
}

.question_box_buttons_images button:hover {
  background-color: #f1f1f1 !important;
  border-color: transparent !important;
}

.question_box_buttons_images button img {
  margin: 4px auto !important;
}

.question_box_range input {
  accent-color: #218ACB !important;
}

#baujahr_progressbar {
  height: 10px;
  width: 0;
  background-color: #218ACB;
}

#progress_bar_box {
  width: 100%;
  background-color: #218ACB;
  color: #fff;
  border: 2px solid #218ACB;
}

#progress_bar_box .progress_step {
  display: inline-block;
  padding: 0 24px 0 24px;
  height: 32px;
  //width: 33%;
}

@media only screen and (max-width: 767px) {
  #progress_bar_box .progress_step {
    width: inherit;
  }
}

#progress_bar_box .progress_step_active {
  background-color: #fff;
  color: #218ACB;
  font-weight: bold;
  padding: 0;
}

.progress_step_arrow_left {
  margin-right: 16px;
  background-color: #fff;
  float: left;
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #218ACB;
}

.progress_step_arrow_right {
  margin-left: 16px;
  background-color: #218ACB;
  float: right;
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #fff;
}

.progress_step_text {
  display: inline-block;
  height: 32px;
}

.progress_step_text_div {
  height: 32px;
  margin-top: 4px;
}

.ergebnis_box {
  border: dotted 2px black;
}

.ergebnis_title h4 {
  font-weight: bold;
  font-size: 125%;
}

table {
  //background-color: #218ACB;
  //color: #fff;
  border-radius: 16px;
  margin-bottom: -24px;
  overflow-wrap: anywhere;
}

table > thead > tr > td {
  color: #fff;
  border-color: #218ACB;
  background-color: #218ACB;
  padding: 8px !important;
}

table > tbody > tr > td {
  border-color: #ddd;
  padding: 8px !important;
}

.result_table_td {
  text-align: left;
}

.result_table_td > a {
  //color: #fff !important;
  text-decoration: underline !important;
}

.rechner-form-info-button {
  //color: #ccc;
  margin: 0;
  font-size: 20px;
  line-height: 0;
}

// TODO farben
.alert-rechner {
  background-color: #ddd;
  color: #000;
  border-color: #ddd;
}

/*.header-button {
  background-color: #fa7d19;
  transition: none !important;
  font-weight: bold;
  font-variant: normal !important;
  font-variant-caps: normal !important;
  text-transform: none;
  font-size: 17px !important;
  line-height: 24px;
}

.header-button:hover {
  border: 0;
  transition: none !important;
  border-radius: 0 !important;
}*/


.weiter_button {
  background-color: #42c37a;
  color: #fff;
  text-decoration: none !important;
}


@media only screen and (max-width: 767px) {
  .weiter_button {
    margin-right: 16px;
  }
}

.weiter_button:hover {
  border-color: #42c37a !important;
  background-color: #46CF81 !important;
}

.mini_mode_select {
  margin: 0 auto;
  margin-top: 5px;
  height: 40px;
}

.weiter_button_mini {
  width: 100%;
  background-color: #218acb;
  border: 0;
}
.weiter_button_mini:hover {
  border-color: #218acb !important;
  background-color: #1f2930 !important;
}

.mini_row {
  margin: 0;
  margin-top: 36px;
}

.mini_text {
  margin: 0 8px -16px 8px;
}

.mini_header_small {
  display: none;
}

.mini_header_large {
  display: block;
}

@media only screen and (max-width: 1199px) {
  .mini_mode_select {
    margin-top: 8px !important;
    margin-bottom: 16px !important;
  }

  .mini_row {
    margin-top: 0;
  }

  .mini_text {
    margin: 0 8px 4px 8px;
  }

  .mini_header_small {
    display: block;
  }

  .mini_header_large {
    display: none;
  }
}

.glyphicon-info-sign {
  cursor: pointer;
}

.alert-rechner-2 {
  border: solid 1px #ddd;
}

.top_header_title_line {
   background-color: #42c37a;
   color: #fff;
   padding: 16px;
   margin-bottom: 0px;
}

#full_modal .modal-content {
  border: 0 !important;
}

.top_header_title_line_modal {
  background-color: #42c37a;
  border-color: #42c37a;
  color: #fff;
}

</style>
